// @flow
import React, { PureComponent } from 'react';

export type StateProps = {};

type Props = StateProps & {
  content: any,
  hideModal: () => void
};

class LogeSuccessModalContent extends PureComponent<Props> {
  render() {
    const { hideModal } = this.props;

    return (
      <div className="modal-content box keep-in-touch-done modal-content--stripe">
        <i className="icon icon-close js-closeModal" onClick={hideModal} role="button" tabIndex={0}></i>
        <div className="modal__wrapper">
          <h3 className="mb-4">Demande de proposition envoyée</h3>
          <div className="modal__body modal__body--medium narrow-2">
            <div className="circle-icon circle-icon--check"></div>
            <span>
              Votre demande a bien été envoyée. Vous serez recontacté dès que possible par l’un de nos commerciaux.
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default LogeSuccessModalContent;
