import 'react-app-polyfill/ie11';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/array';
import 'core-js/es/object';
import 'core-js/features/url-search-params';
import 'unorm';

import React from 'react';
import ReactDOM from 'react-dom';
import { initWindowApp } from 'utils/windowUtils';
import App from 'components/app/AppProvider';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';
import ReactBatch from './tracking/batch';

import packageJson from '../package.json';

import 'ie-array-find-polyfill';
import 'raf/polyfill';
import 'utils/ieUtils';
import './polyfill-includes';
import 'assets/css/style.css';
import 'assets/css/surcharge.css';
import 'assets/css/surcharge.scss';
//import 'assets/css/topbar.css';
import 'assets/css/cookie.css';
import 'assets/css/trouverclub.css';

Sentry.init({
  dsn: 'https://2acdaac2b5d245f68af8bf91252d7fb4@sentry.dev.ffr.fr/21',
  release: packageJson.version,
  environment: process.env.NODE_ENV
});

// BATCH
ReactBatch.init({
  apiKey: 'B35A2E59CBB045D08E5122E9DFB0D130',
  subdomain: 'ffr',
  authKey: '2.CdBWzXQmTpZibfFHisCB8phLmRxTmnngYO+cQ68vm6E=',
  dev: !(process.env.NODE_ENV === 'production' && window.location.hostname === 'www.ffr.fr'),
  vapidPublicKey: 'BEewijfitf1Lx1YVfyrWouiL0enIlNwRpj/ZmD9d+xqUzA9e2DYA2JOp6/dCUgE3wc1TqpQAaDj5GmG5p05p6c0=',
  ui: {},
  defaultIcon: 'https://www.ffr.fr/favicon/android-chrome-192x192.png', // for Chrome desktop
  smallIcon: 'https://www.ffr.fr/favicon/favicon.png' // for Chrome Android
});

initWindowApp();

// COOKIES
const initCookies = () => {
  // GTM
  const tagManagerArgs = {
    gtmId: 'GTM-W6DH5H7',
    dataLayerName: 'dataLayer'
  };
  TagManager.initialize(tagManagerArgs);
};

const root = document.getElementById('root');
const app = <App acceptCookies={initCookies} />;
ReactDOM.render(app, root);
