// @flow
import React, { PureComponent } from 'react';
import { formatFullDate } from 'utils/dateUtils';
import { dynamicClassName } from 'utils/dynamicClassName';
import Label from 'components/fragments/text/Label';
import { getDateFromString } from 'utils/dateUtils';
import { handleTagEvent } from 'utils/tagManagerUtils';
import { socialNetworks } from 'utils/socialShareUtils';
import type { FullUserType } from 'types/User';

export type StateProps = {
  userPref: FullUserType
};

type Props = StateProps & {
  category?: string,
  title: string,
  date: Date | string,
  label: string,
  src: string,
  label_download: string,
  sidebar?: boolean
};

type State = {
  shareOpen: boolean
};

class Download extends PureComponent<Props, State> {
  props: Props;

  static defaultProps = {
    title: 'title',
    date: new Date(),
    label: 'label',
    src: '#',
    label_download: 'Télécharger'
  };

  state = {
    shareOpen: false
  };

  toggleShare = () => {
    this.setState({ shareOpen: !this.state.shareOpen });
  };

  renderSocialButtons = (): Array<React$Element<any>> => {
    const { userPref, src } = this.props;

    return Object.keys(socialNetworks).map(key => {
      const ShareButton = socialNetworks[key];
      const classNames =
        key === 'mail'
          ? 'btn btn--social btn--small btn--primary btn--medium'
          : `btn btn--social btn--small btn--${key} btn--medium`;

      return (
        <ShareButton
          url={src}
          className={classNames}
          role="button"
          tabIndex={0}
          onClick={handleTagEvent('partage', this.props.title, key, userPref)}
          key={`${key}-share-button`}
        >
          <i className={`icon icon-${key}`} />
        </ShareButton>
      );
    });
  };

  onDownloadClick = (title: string, src: string) => {
    const { userPref } = this.props;
    const { pathname } = window.location;

    return handleTagEvent(
      'telechargement_document',
      title,
      '',
      userPref,
      handleTagEvent(
        'module_document',
        `download document ${title}`,
        pathname,
        userPref,
        handleTagEvent('module_contenu', `clic ${title}`, pathname, userPref)
      )
    );
  };

  render() {
    const { category, title, label, sidebar, src, label_download } = this.props;
    const date = getDateFromString(this.props.date);
    const classNamesShareOpen = dynamicClassName('share');
    this.state.shareOpen && classNamesShareOpen.add('is-open');

    const classNamesContainer = dynamicClassName('box box--white card card--download mb-1');
    sidebar ? classNamesContainer.add('mb-4 mb-md-5') : classNamesContainer.add('card--large');

    return (
      <div className={classNamesContainer.build()}>
        <div className="card__body">
          {category && <p className="card__category ft-h6 ft-up">{category}</p>}
          <h4 className="card__title">
            <a title={`télécharger le document ${title}`} href={src} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
            <Label isGrey isSmall label={label} />
          </h4>
          <p className="card__desc ft-h6">{formatFullDate(date)}</p>
        </div>
        <div className="card__footer">
          <div className={classNamesShareOpen.build()}>
            <button className="btn btn--white" onClick={this.toggleShare} aria-label="Partager">
              <i className="icon icon-share" />
            </button>
            <div className="share__wrapper">
              <i className="icon icon-delete" role="button" tabIndex={0} onClick={this.toggleShare} />
              {this.renderSocialButtons()}
            </div>
          </div>
          <a
            title={`Télécharger le document ${title}`}
            href={src}
            onClick={() => this.onDownloadClick(title, src)}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn--primary btn--full"
          >
            <i className="icon icon-download is-inline" />
            <span>{label_download}</span>
          </a>
        </div>
      </div>
    );
  }
}

export default Download;
