// @flow
import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import TopBar from 'containers/header/TopBarContainer';
import TopBarMobile from 'components/header/TopBarMobile';
import MenuContainer from 'containers/header/MenuContainer';
import HeaderMobile from 'containers/header/HeaderMobileContainer';
import { setBodyStyle, resetBodyStyle } from 'utils/bodyUtils';
import { dynamicClassName } from 'utils/dynamicClassName';
import HeaderFindClubContainer from 'containers/header/HeaderFindClubContainer';
import type { CompetitionsInternationalesLink } from 'types/competitionsInternationales';
import { headerFilterClubWithlistPaths, isEspacePerso } from 'constants/menuConstants';

type Props = {
  location: any,
  current_competition: CompetitionsInternationalesLink
};

type ComponentState = {
  isMobileMenuOpen: boolean,
  isMobileMenuSticky: boolean,
  lastScrollPosition: number,
  hasOverlay: boolean
};

class Header extends PureComponent<Props, ComponentState> {
  state: ComponentState = {
    isMobileMenuOpen: false,
    isMobileMenuSticky: false,
    lastScrollPosition: 0,
    hasOverlay: false
  };

  setOverlay = (hasOverlay?: boolean) => {
    this.setState({ hasOverlay });
  };

  onScrollPosition = () => {
    /*const { location } = this.props;
    const { lastScrollPosition, isMobileMenuSticky } = this.state;
    const screenWidth = window.innerWidth;
    const scrollPosition = window.scrollY;
    const height = location.pathname.match('tableau-de-bord') ? 100 : 250;
    if (screenWidth < 1080) {
      if (lastScrollPosition > scrollPosition && scrollPosition > height) {
        if (!isMobileMenuSticky) {
          this.setState({ isMobileMenuSticky: true });
        }
      } else if (location.pathname.match('tableau-de-bord') && scrollPosition > height) {
        if (!isMobileMenuSticky) {
          this.setState({ isMobileMenuSticky: true });
        }
      } else if (isMobileMenuSticky) {
        this.setState({ isMobileMenuSticky: false });
      }
    } else if (screenWidth >= 1080) {
      if (isMobileMenuSticky) {
        this.setState({ isMobileMenuSticky: false });
      }
    }

    this.setState({ lastScrollPosition: scrollPosition });*/
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScrollPosition);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollPosition);
  }

  onToggleMenu = () => {
    if (this.state.isMobileMenuOpen) this.onCloseMenu();
    else this.onOpenMenu();
  };

  onOpenMenu = () => {
    setBodyStyle({
      position: 'fixed',
      width: '100%',
      top: 0
    });
    this.setState({ isMobileMenuOpen: true });
  };

  onCloseMenu = () => {
    resetBodyStyle();
    this.setState({ isMobileMenuOpen: false });
  };

  render() {
    const { location, current_competition } = this.props;
    const { isMobileMenuOpen, isMobileMenuSticky, hasOverlay } = this.state;
    const classNamesHeaderWrapper = dynamicClassName('header__wrapper');
    const isHome = location.pathname === '/';
    isHome && !isMobileMenuOpen && classNamesHeaderWrapper.add('blue-background');
    isMobileMenuOpen && classNamesHeaderWrapper.add('is-visible');
    const classNamesHeader = dynamicClassName('header');

    const header_over =
      (!isHome &&
        !location.pathname.match('actualites/.*/.*') &&
        !location.pathname.match('resultats-de-recherche') &&
        !location.pathname.match('clubs/') &&
        (!location.pathname.match(/evenements\/[a-z-0-9]/g) ||
          location.pathname.match(/evenements\/[0-9]{4}\/[a-z]/g))) ||
      location.pathname.match('/histoires-de-rugby/resultats-de-recherche') ||
      location.pathname.match('/histoires-de-rugby/carte');
    // const header_over = false;
    header_over && classNamesHeader.add('header--over');
    header_over && classNamesHeader.add('header--transparent');
    //isMobileMenuSticky && classNamesHeader.add('header--fixed');

    if (isEspacePerso(location.pathname)) {
      classNamesHeader.add('header--dashboard');
      classNamesHeader.remove('header--transparent');
      classNamesHeader.remove('header--over');
    }

    if (current_competition && current_competition.slug) {
      classNamesHeader.add('has-current-competition');
    }

    return (
      <>
        <TopBar />
        <TopBarMobile />
        <header className={classNamesHeader.build()}>
          <div className={classNamesHeaderWrapper.build()}>
            <MenuContainer onCloseMenu={this.onCloseMenu} />
          </div>
          <HeaderMobile
            isMenuOpen={isMobileMenuOpen}
            headerOver={header_over && !isMobileMenuSticky}
            onToggleMenu={this.onToggleMenu}
            onCloseMenu={this.onCloseMenu}
            setOverlay={this.setOverlay}
            hasOverlay={hasOverlay}
            current_competition={current_competition}
          />
          {headerFilterClubWithlistPaths.includes(location.pathname) && <HeaderFindClubContainer location={location} />}
        </header>
      </>
    );
  }
}

export default withRouter(Header);
