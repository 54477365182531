// @flow
import React from 'react';

import OptoutPiano from 'components/Didomi/OptoutPiano';
import Embedtwitch from 'components/embedTwitch';
import CompetitionClassementTitle from 'components/fragments/CompetitionClassementTitle';
import CompetitionLinearCourseTitle from 'components/fragments/CompetitionLinearCourseTitle';
import MatchDataForPost from 'components/fragments/MatchDataForPost';
import NewsListElementContentBloc from 'components/fragments/NewsListElementContentBloc';
import PlayerSmallGroupCard from 'components/fragments/PlayerSmallGroupCard';
import RencontreItlNextMatch from 'components/fragments/RencontreItlNextMatch';
import SingleMatchCardBlocContent from 'components/fragments/SingleMatchCardBlocContent';
import OpenStreetMap from 'components/fragments/map/OpenStreetMap';
import YoutubePlaylist from 'components/fragments/YoutubePlaylist';
import YoutubePlaylistCarousel from 'components/fragments/YoutubePlaylistCarousel';
import YoutubePlaylistHome from 'components/fragments/YoutubePlaylistHome';
import HTMLGallery from 'components/newsletter/blocs/HTMLGallery/HTMLGallery';
import HTMLAccordion from 'components/newsletter/blocs/HTMLAccordion/HTMLAccordion';
import Countdown from 'components/newsletter/blocs/CountDown';
import NewsletterSubscriberContainer from 'containers/footer/NewsletterSubscriberContainer';
import HtmlScript from 'components/newsletter/blocs/HtmlScript';
import ClubFinderHub from 'containers/club/ClubFinderHubContainer';
import CompetitionsInternationalesArticlesContainer from 'containers/competitionsInternationales/competitionsInternationalesArticlesContainer';
import Download from 'containers/fragments/DownloadContainer';
import CardEnDirect from 'containers/fragments/CardEnDirectContainer';
import HtmlElementWrapper from 'containers/newsletter/blocs/HtmlElementWrapperContainer';
import MyFavoriteClubs from 'containers/profile/dashboardHome/MyFavoriteClubsContainer';
import MyNewsletterFeed from 'containers/profile/dashboardHome/MyNewsletterFeedContainer';
import MyTeamHome from 'containers/profile/dashboardHome/MyTeamHomeContainer';
import PrivatePushEvent from 'containers/profile/dashboardHome/PrivatePushEventContainer';
import FormProfil from 'containers/profile/FormProfilContainer';
import { blacklist } from 'constants/newsletterBlocConstants';
import { FB_API_KEY } from 'constants/app.js';
import FacebookProvider, { EmbeddedPost } from 'react-facebook-sdk';

import './blocks.css';

const ContentBloc = (props: any) => {
  const { attributes, children, tagName } = props;

  switch (tagName) {
    case 'embed-twitch':
      return <Embedtwitch attributes={attributes} />;

    case 'gallery':
      return <HTMLGallery childrens={children} attributes={attributes} tagName={tagName} />;

    case 'countdown':
      return (
        <Countdown
          date={attributes[0] && attributes[0].value ? attributes[0].value : new Date().toString()}
          message={attributes[1] && attributes[1].value ? attributes[1].value : ''}
        />
      );

    case 'newsletter':
      let attrs = {};
      attributes.map(attr => {
        attrs[attr.name] = attr.value;
        return attr;
      });
      return (
        <div className="box card card--newsletter mb-4">
          <div className="card__thumbnail img-blue-dark" style={{ backgroundImage: 'url(/img/card/bg-game.jpg)' }} />
          <NewsletterSubscriberContainer mode={'sidebar'} {...attrs} />
        </div>
      );

    case 'accordeon': {
      if (!children) {
        return null;
      }
      return <HTMLAccordion childrens={children} attributes={attributes} tagName={tagName} />;
    }

    case 'carte-geojson':
      if (attributes.length > 0 && attributes[0].name == 'src') {
        return (
          <OpenStreetMap
            geojson={attributes[0].value}
            height={'450'}
            geolocation={false}
            style={'carte--centenaire'}
            long={0}
            lat={0}
            zoom={3}
          />
        );
      } else {
        return null;
      }

    case 'download': {
      const attrs = {};
      attributes.forEach(function(attr, index) {
        attrs[attr.name] = attr.value;
      });
      return <Download {...attrs} />;
    }

    case 'cardendirect':
      return <CardEnDirect />;

    case 'facebook': {
      const attr = props.attributes.find(attr => attr.name === 'cite');
      if (attr) {
        return (
          <FacebookProvider appId={FB_API_KEY}>
            <EmbeddedPost href={attr.value} />
          </FacebookProvider>
        );
      }

      break;
    }

    // PAGE EVENEMENTIELLE
    case 'rencontres-itl-next-match': {
      const attrs = {};
      attributes.forEach(function(attr, index) {
        attrs[attr.name] = attr.value;
      });

      return <RencontreItlNextMatch {...attrs} />;
    }

    case 'youtube-playlist': {
      const attrs = {};
      attributes.forEach(function(attr, index) {
        attrs[attr.name] = attr.value;
      });

      if (attrs.ishomeplaylist === 'true') {
        return <YoutubePlaylistHome attrs={attrs} />;
      } else if (attrs.iscarouselplaylist === 'true') {
        return <YoutubePlaylistCarousel attrs={attrs} />;
      } else {
        return <YoutubePlaylist {...attrs} />;
      }
    }

    case 'rencontres-itl-competition-news': {
      const attrs = {};
      attributes.forEach(function(attr, index) {
        attrs[attr.name] = attr.value;
      });
      // console.log(attrs);
      const slug = attrs.tagslug;
      if (!slug) {
        return null;
      }

      return <CompetitionsInternationalesArticlesContainer slug={slug} {...attrs} childrens={children} />;
    }

    case 'player-small-group-card':
      return <PlayerSmallGroupCard attributes={attributes} />;
  }

  if (tagName === 'match-data-for-post') {
    return <MatchDataForPost attributes={attributes} />;
  }

  if (tagName === 'competition-linear-course-title') {
    return <CompetitionLinearCourseTitle attributes={attributes} />;
  }

  if (tagName === 'competition-classement-title') {
    return <CompetitionClassementTitle attributes={attributes} />;
  }

  if (tagName === 'single-match-card') {
    return <SingleMatchCardBlocContent attributes={attributes} />;
  }

  if (tagName === 'news-list-element') {
    return <NewsListElementContentBloc attributes={attributes} />;
  }
  if (tagName === 'optout-piano') {
    return <OptoutPiano />;
  }

  //* Private Account Dashboard
  if (tagName === 'club-finder-hub') {
    return (
      <div className="mb-7">
        <ClubFinderHub private_account={true} />
      </div>
    );
  }

  if (tagName === 'form-profile') {
    return <FormProfil />;
  }

  if (tagName === 'my-favorite-clubs') {
    return (
      <div className="mt-7">
        <MyFavoriteClubs />
      </div>
    );
  }

  if (tagName === 'my-team-home') {
    return <MyTeamHome {...props} />;
  }

  if (tagName === 'my-newsletter-feed') {
    return (
      <MyNewsletterFeed
        articles={props.articles}
        handleDropdownCategory={props.handleDropdownCategory}
        displayMainNews={props.displayMainNews}
        loading={props.loading}
        selectedArticleCategory={props.selectedArticleCategory}
        show_link_preferences={props.show_link_preferences}
      />
    );
  }

  if (tagName === 'private-push-event') {
    return <PrivatePushEvent {...props} />;
  }

  //* Else
  if (!blacklist.includes(tagName)) {
    return <HtmlElementWrapper childrens={children} attributes={attributes} tagName={tagName} />;
  } else {
    return <HtmlScript childrens={children} attributes={attributes} tagName={tagName} />;
  }
};

export default ContentBloc;
