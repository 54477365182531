/* eslint-disable max-len */
// @flow
import React, { PureComponent } from 'react';

import Label from 'components/fragments/text/Label';
import TopBarUserConnection from 'containers/header/TopBarUserConnectionContainer';
import type { MenuElementType } from 'types/MenuElement';
import type { RouterProps } from 'types/Router';
import type { FullUserType } from 'types/User';
import { dynamicClassName } from 'utils/dynamicClassName';
import { handleTagEvent } from 'utils/tagManagerUtils';
import type { CompetitionsInternationalesLink } from 'types/competitionsInternationales';

import TopBarDropdown from './TopBarDropdown';

export type DispatchProps = {};

type StateProps = {
  topBarElements: MenuElementType[],
  tickerElement: {
    link: string,
    title: string,
    label: string
  },
  userPref: FullUserType,
  keycloakData: any,
  current_competition: CompetitionsInternationalesLink
};

type Props = DispatchProps &
  StateProps &
  RouterProps & {
    isMobile?: boolean,
    setIsTopBarOpen?: (isTopBarOpen: boolean) => void
  };

type State = {
  isAccordionOpen: boolean,
  loggedIn: boolean
};

class TopBar extends PureComponent<Props, State> {
  static defaultProps = {
    topBarElements: []
  };

  state: State = {
    isAccordionOpen: false,
    loggedIn: false
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    const {
      userPref,
      userPref: { identifie }
    } = props;
    let newState = {};

    if (userPref) {
      newState.loggedIn = identifie;
    }
    if (newState.loggedIn || newState.loggedIn !== state.loggedIn) {
      return newState;
    }
    return null;
  }

  onLinkClick = (userPref: FullUserType, link: string) => {
    const { isMobile, setIsTopBarOpen } = this.props;

    if (isMobile && setIsTopBarOpen) {
      setIsTopBarOpen(false);
    }

    return handleTagEvent('menu_transverse', 'clic_lien_sortant', link, userPref);
  };

  renderTopBarElements = () => {
    const { isMobile, setIsTopBarOpen, topBarElements, userPref } = this.props;
    const menus = topBarElements.filter(item => item.parent === 0);

    return menus.map<any>((menu, index) => {
      if (menu.items && menu.items.length > 0) {
        return (
          <TopBarDropdown
            key={index}
            isMobile={isMobile}
            setIsTopBarOpen={setIsTopBarOpen}
            subMenus={menu.items}
            title={menu.title}
            userPref={userPref}
          />
        );
      }

      return (
        <li key={index}>
          <a
            href={menu.url}
            title={`Se rendre sur le site officiel "${menu.title}" de la Fédération Française de Rugby (nouvel onglet)`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => this.onLinkClick(userPref, menu.url)}
          >
            {menu.title}
          </a>
        </li>
      );
    });
  };

  handleAccordionOpen = (isOpen: boolean) => {
    this.setState(prevState => ({ isAccordionOpen: isOpen }));
  };

  render() {
    const { isMobile, tickerElement } = this.props;
    const { isAccordionOpen } = this.state;
    // CLASSES
    const topbarDynamicClasses = dynamicClassName('topbar');

    if (isAccordionOpen) {
      topbarDynamicClasses.add('has-accordion-open');
    } else {
      topbarDynamicClasses.remove('has-accordion-open');
    }

    return (
      <nav className={topbarDynamicClasses.build()}>
        <ul>
          {this.renderTopBarElements()}
          {tickerElement && tickerElement.link && tickerElement.title && (
            <li className={'topbar__news'}>
              <a href={tickerElement.link} title={tickerElement.title}>
                <Label isSmall isBlueAlt label={tickerElement.label ? tickerElement.label : 'la fédération'} />
                <span>{tickerElement.title}</span>
              </a>
            </li>
          )}

          {!isMobile && <TopBarUserConnection handleUserAccordionOpen={this.handleAccordionOpen} />}

          <li className="display-from-medium-screen btn btn--red-dark">
            <a target="_blank" href="https://report.whistleb.com/fr/ffr" rel="noreferrer">
              Saisir la C3PR
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default TopBar;
