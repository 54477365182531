import React from 'react';
import { DidomiSDK } from '@didomi/react';
// import ReactPixel from 'react-facebook-pixel';

// import { initWindowApp } from 'utils/windowUtils';

function Didomi() {
  const didomiIsReady = didomi => {
    // console.log('didomi', didomi)
    // console.log('use consent', didomi.getCurrentUserStatus())
    // console.log('vendors', didomi.getVendors())
    const userStatusForFacebook = didomi.getUserStatusForVendor('facebook');
    handleFacebook(userStatusForFacebook);
  };

  const consentHasChanged = cwtToken => {
    console.log('A consent has been given/withdrawn', cwtToken.consents);
    const consents = cwtToken.consents;

    const cookieConsents = consents.find(item => item.purpose === 'cookies');
    handleFacebookStatusChange(cookieConsents);

    // console.log('cookieConsents', cookieConsents);
  };

  const handleFacebookStatusChange = cookieConsents => {
    const newConsent = cookieConsents.find(item => item.id === 'facebook');

    if (newConsent) {
      handleFacebook(newConsent);
    }
  };

  const handleFacebook = userConsent => {
    console.log('facebook user consent', userConsent);
    // FACEBOOK PIXEL
    // const PixelAdvancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    // const PixelOptions = {
    //   autoConfig: true, // set pixel's autoConfig
    //   debug: false // enable logs
    // };
    // const PixelId = '527068497636910'; // change id 2022-01-05
    // ReactPixel.init(PixelId, PixelAdvancedMatching, PixelOptions);
    // ReactPixel.fbq('consent', 'revoke');
    // initWindowApp();
    // if (userConsent) {
    //   ReactPixel.init(PixelId, PixelAdvancedMatching, PixelOptions);
    //   ReactPixel.fbq('consent', 'grant');
    // } else {
    //   ReactPixel.fbq('consent', 'revoke');
    // }
  };

  return (
    <DidomiSDK
      iabVersion={2}
      gdprAppliesGlobally={true}
      onReady={didomi => didomiIsReady(didomi)}
      onConsentChanged={cwtToken => consentHasChanged(cwtToken)}
      onNoticeShown={() => console.log('Didomi Notice Shown')}
      onNoticeHidden={() => console.log('Didomi Notice Hidden')}
      // onPreferencesClickVendorAgree={vendorId => console.log('Didomi Preferences Click Vendor Agree', vendorId)} => retourne undefined pour l'id du vendor...
      // onPreferencesClickVendorDisagree={vendorId => console.log('Didomi Preferences Click Vendor Disagree', vendorId)}
    />
  );
}

export default Didomi;
