// @flow

import {
  APP_SET_PROFILE_LATER,
  APP_SET_REMATCH_VOTE,
  APP_USER_SUBSCRIBE_NEWSLETTER,
  APP_USER_CONNECTED_DASHBOARD,
  APP_NEWSLETTER_INCREASE_COUNT,
  APP_DASHBOARD_INCREASE_COUNT
} from 'actions/appActions';

import type { ActionType } from 'types/Actions';

export type State = {
  showCookieInfo: boolean,
  rematchVote: { club: string, id: string, url: string, thumb: string },
  newsletterSubscribed: boolean,
  dashboardConnected: boolean,
  newsletterPopInCount: number,
  dashboardPopInCount: number,
  profileLater: number
};

const initialState: State = {
  showCookieInfo: true,
  rematchVote: { club: '', id: '', url: '', thumb: '' },
  newsletterSubscribed: false,
  dashboardConnected: false,
  newsletterPopInCount: 0,
  dashboardPopInCount: 0,
  profileLater: 0
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case APP_SET_PROFILE_LATER:
      return {
        ...state,
        profileLater: action.payload.profileLater
      };
    case APP_SET_REMATCH_VOTE:
      return {
        ...state,
        rematchVote: action.payload.rematchVote
      };
    case APP_USER_SUBSCRIBE_NEWSLETTER:
      return {
        ...state,
        newsletterSubscribed: action.payload.newsletterSubscribed
      };
    case APP_USER_CONNECTED_DASHBOARD:
      return {
        ...state,
        dashboardConnected: action.payload.dashboardConnected
      };
    case APP_NEWSLETTER_INCREASE_COUNT:
      if (state.newsletterPopInCount < 2) {
        return {
          ...state,
          newsletterPopInCount: state.newsletterPopInCount + 1
        };
      }
      return { ...state };
    case APP_DASHBOARD_INCREASE_COUNT:
      if (state.dashboardPopInCount < 20) {
        return {
          ...state,
          dashboardPopInCount: state.dashboardPopInCount + 1
        };
      }
      return { ...state };
    default:
      return state;
  }
}
