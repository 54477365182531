// @flow
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import EventDrivenPageBanner from 'components/header/EventDrivenPageBanner';
import Search from 'components/header/Search';

import { dynamicClassName } from 'utils/dynamicClassName';
import { isEspacePerso } from 'constants/menuConstants';
import type { RouterProps } from 'types/Router';
import type { CompetitionsInternationalesLink } from 'types/competitionsInternationales';
import type { FullUserType } from 'types/User';
import { handleTagEvent } from 'utils/tagManagerUtils';

type StateProps = {
  keycloakData: any,
  logo: string,
  logoOver: string,
  userPref: FullUserType
};

type Props = {
  onToggleMenu: Function,
  isMenuOpen: boolean,
  headerOver: boolean,
  onCloseMenu: Function,
  setOverlay: Function,
  hasOverlay: boolean,
  current_competition: CompetitionsInternationalesLink
} & StateProps &
  RouterProps;

class HeaderMobile extends PureComponent<Props> {
  handleToogleMenu = () => {
    const { onToggleMenu, setOverlay } = this.props;

    onToggleMenu();
    setOverlay(false);
  };

  render() {
    const {
      current_competition,
      isMenuOpen,
      hasOverlay,
      headerOver,
      location: { pathname },
      logo,
      logoOver,
      onCloseMenu,
      setOverlay,
      userPref
    } = this.props;
    const classNamesIconShowMenu = dynamicClassName('icon icon-menu js-showMenu');
    const classNamesOverlay = dynamicClassName('overlay');
    isMenuOpen && classNamesIconShowMenu.add('is-open');
    hasOverlay && classNamesOverlay.add('is-visible');

    if (isEspacePerso(pathname)) {
      return (
        <>
          <div className="header__mobile">
            <Link title="Se rendre sur la page d'accueil" to="/tableau-de-bord">
              <img className="logo-blue" src={logo} alt="logo FFR" />
              <h1 className="ft-up ft-h5">ESPACE PERSO</h1>
            </Link>
            <i className={classNamesIconShowMenu.build()} role="button" tabIndex={0} onClick={this.handleToogleMenu} />
          </div>
          <div
            className={classNamesOverlay.build()}
            onClick={this.handleToogleMenu}
            role="button"
            tabIndex={0}
            aria-label={'Afficher le menu'}
          />
        </>
      );
    } else {
      return (
        <>
          <div className="header__mobile">
            <Link title="Se rendre sur la page d'accueil" to="/" onClick={onCloseMenu}>
              <img src={headerOver ? logoOver : logo} alt="" />
            </Link>
            <div className="header__mobile__right">
              <Search mobile={true} onSetOverlay={setOverlay} onCloseMenu={onCloseMenu} />

              <Link
                to="/trouver-un-club-de-rugby"
                title="Se rendre sur la page de recherche d'un club"
                className=""
                onClick={handleTagEvent(
                  'recherche geolocalisee',
                  'clic trouver un club',
                  this.props.location.pathname,
                  userPref
                )}
              >
                <i className="icon icon-place is-inline" />
              </Link>

              <i
                className={classNamesIconShowMenu.build()}
                role="button"
                tabIndex={0}
                onClick={this.props.onToggleMenu}
              />
            </div>
          </div>
          {current_competition &&
            current_competition.label &&
            current_competition.slug &&
            this.props.location.pathname === '/' && (
              <div className="mobile-tablet-only">
                <EventDrivenPageBanner current_competition={current_competition} location={this.props.location} />
              </div>
            )}
          <div
            className={classNamesOverlay.build()}
            onClick={onCloseMenu}
            role="button"
            tabIndex={0}
            aria-label={'Fermer ce menu'}
          />
        </>
      );
    }
  }
}

export default HeaderMobile;
